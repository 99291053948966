<template>
    <Page title="ALTERAR DADOS" backTo="home" :loading="loading">
        
        <div class="py-6 mb-10">

            <div class="mb-16"><Input name="name" label="Nome:" placeholder="Nome" v-model="name" /></div>

            <div class="mb-16"><Input name="cpf" label="CPF:" mask="999.999.999-99" placeholder="000.000.000-00" v-model="cpf" /></div>

            <div class="mb-16"><Input name="birthDate" label="Data Nascimento:" mask="99/99/9999" placeholder="00/00/0000" v-model="birthDate" /></div>

            <div class="mb-16"><Input name="phone" label="Celular:" mask="(99) 99999-9999" placeholder="(11) 00000-0000" v-model="phone" /></div>

            <button class="btn btn-blue btn-lg mb-12" @click="save">SALVAR</button>
        </div>
        
    </Page>
</template>

<script>
import Page from '../../components/app/Page'
import Input from '../../components/ui/form/Input'

export default {
    components: { Page, Input },

    data() {
        return {
            loading: false,
            name: '',
            cpf: '',
            birthDate: '',
            phone: ''
        }
    },

    methods: {

        save() {

            this.loading = true

            setTimeout(() => {

                this.$notify({
                    group: "success",
                    title: "Sucesso",
                    text: 'Dados atualizados com sucesso!'
                }, 3000);

                this.loading = false
                this.$router.push({name: 'home'})
            }, 2000)
        }
    }
}
</script>