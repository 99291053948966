const state = {
  onixCode: "5142",
  userToken:
    sessionStorage.getItem("usertoken") ||
    "U2FsdGVkX19wAKfdYbRA72PiXAOZLXCU3dztBHyZk+MXHQNC6UFVTmZiOXE0ThJ5tS7YeStXK/DDaW3f/QWbX8TOndZS40xfZAB9peOcClYcW4sp4syWnQcWN2ciXBVDcMdFw3vKhEE/mb84sPOrih9mlXe97/zustm4zp/XupLADgYJNW48/BRYT5qFmurVNQWIf5dKM38BwpGylRj60v3m4ugvNMU2AmnYxBigiC4=",
  tokenzeus: sessionStorage.getItem("tokenzeus"),
  loggedUser: sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : null,
}

const mutations = {
  UPDATE_USER_TOKEN(state, userToken) {
    sessionStorage.setItem("usertoken", userToken),
      (state.userToken = userToken)
  },

  DELETE_USER_TOKEN(state) {
    sessionStorage.removeItem("usertoken"), (state.userToken = null)
  },

  setLogged(state, { tokenzeus, loggedUser }) {
    sessionStorage.setItem("tokenzeus", tokenzeus),
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...state.loggedUser, ...loggedUser })
      ),
      (state.tokenzeus = tokenzeus)
    state.loggedUser = { ...state.loggedUser, ...loggedUser }
  },
}

const actions = {
  setUserToken({ commit }, userToken) {
    commit("UPDATE_USER_TOKEN", userToken)
  },

  resetUserToken({ commit }) {
    commit("DELETE_USER_TOKEN")
  },
}

const getters = {
  isLoggedIn: (state) => {
    return state.tokenzeus != null && state.loggedUser != null
  },

  onixCode: (state) => state.onixCode,

  userToken: (state) => state.userToken,

  tokenzeus: (state) => state.tokenzeus,

  loggedUser: (state) => state.loggedUser,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
