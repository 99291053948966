import axios from "axios"
import ClientBusinessErrorException from "./exceptions/ClientBusinessErrorException"
import ClientErrorException from "./exceptions/ClientErrorException"
import ServerErrorException from "./exceptions/ServerErrorException"
import router from "./router"

let API = axios.create()

API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response)
  },
  (error) => {
    const HTTPStatusCode = error.response ? error.response?.status : 0
    let errorResp = error

    switch (true) {
      case HTTPStatusCode === 490:
        errorResp = new ClientBusinessErrorException(error.response.data?.error)
        break
      case HTTPStatusCode >= 400 && HTTPStatusCode < 490:
        errorResp = new ClientErrorException(error.response.data?.error)
        break
      case HTTPStatusCode >= 500:
        if (error.response.data?.error == "TokenZeus invalido.") {
          router.push({ name: "login" })
        } else {
          errorResp = new ServerErrorException(error.response.data?.error)
        }
        break
      default:
        console.error(error)
        router.push({ name: "login" })
    }

    return Promise.reject(errorResp)
  }
)

const ApiURLs = {
  QA_LOJA: "https://loja.meutem.com.br",
  QA_API: "https://api.tempravoce.com",
  PAINEL: "https://api.dd.meutem.com.br/v1/painel",
  PROXY_MEUTEM: "http://app.api.tempravoce.com",
}

const AppointmentsURLs = {
  LOAD_ZIPCODE: (zipcode) => {
    return ApiURLs.QA_LOJA + "/buscaCep/getCep/" + zipcode
  },

  CREATE_SCHEDULE: ApiURLs.PROXY_MEUTEM + "/app/tickets/appointment",

  CREATE_SCHEDULE_EXAM: ApiURLs.PROXY_MEUTEM + "/app/tickets/exam",

  SPECIALITIES: ApiURLs.PAINEL + "/specialities",
}

const AccountsURLs = {
  DEPENDENTS_LIST: ApiURLs.QA_API + "/tem_dependente",
  DEPENDENTS_ADHESION: ApiURLs.QA_API + "/tem_adesao",

  VENUES_SEARCH: ApiURLs.QA_LOJA + "/nova-busca/estado/clinicas",
}

const CardURL = (onix, client_channel_id) => {
  return `${ApiURLs.PROXY_MEUTEM}/cards/get-card-by-client-onix-ln/${onix}/${client_channel_id}`
}

const DrugstoresURLs = {
  SEARCH: ApiURLs.PROXY_MEUTEM + "/app/drugstores/search",
}

export { API, ApiURLs, AccountsURLs, AppointmentsURLs, DrugstoresURLs, CardURL }
