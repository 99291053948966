<template>
    <div>
        <InputGroup :label="label">
            <select :value="value" @change="handleChange" @input="$emit('input', $event.target.value)" :required="required">
                <option></option>
                <option v-for="(item, index) in items" :key="index" :selected="item == value">{{item}}</option>
            </select>
        </InputGroup>
    </div>
</template>

<script>
import InputGroup from './InputGroup'

export default {
    components: { InputGroup },

    props: ['label', 'items', 'value', 'required'],

    methods: {

        handleChange() {
        },
    }
}
</script>
