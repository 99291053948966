<template>
    <Page back-to="home" title="BUSCAR FARMÁCIAS" :loading="loading">   
            
        <div class="my-16"><Input name="zipcode" label="Digite o seu CEP:" mask="99999-999" placeholder="00000-000" v-model="zipcode" @input="handleZipcodeChange" /></div>

        <div class="mb-16"><Input name="city" label="Nome da Rua / Avenida:" placeholder="Nome da Rua" v-model="address.route" /></div>

        <button class="btn btn-blue btn-lg mb-12" @click="search" :disabled="canSearch()">BUSCAR</button>

        <h4 class="text-gray-500 font-medium text-left">Lembre-se de mostrar seu cartão virtual com o símbolo da VIDALINK no balcão para obter desconto. (<a href="#">ver cartão</a>)</h4>

        <div class="mt-10" id="results">
            <div v-for="venue in venues" :key="venue.id" class="rounded-lg shadow mb-10 px-4">

                <div class="text-center font-bold text-xl py-5 px-3 border-b text-gray-500">
                    {{ venue.name }}
                </div>
                <div class="text-left px-2 py-6">
                    <h4 class="font-bold mb-1">Endereço:</h4>
                    <p class="text-gray-500 fond-medium leading-5">
                        {{ venue.address }} {{ venue.number }}, {{ venue.neighborhood }}, {{ venue.city }} - {{ venue.state }}
                        <br>
                        CEP {{ venue.postal_code }}
                    </p>

                    <div v-if="venue.telephone" class="mt-4">
                        <h4 class="font-bold mb-1">Telefone</h4>
                        <p class="text-gray-500 fond-medium leading-5">{{ venue.telephone }}</p>
                    </div>
                </div>
            </div>
        </div>
    </Page>
</template>

<script>
import Page from '../../components/app/Page'
import Input from '../../components/ui/form/Input'
import { loadZipcode } from '../../helpers'
import { DrugstoresURLs } from '../../api'

const VueScrollTo = require('vue-scrollto')

export default {
    components: { Page, Input },

    data() {
        return {
            loading: false,
            zipcode: '',
            address: '',
            venues: []
        }
    },

    methods: {

        canSearch(){
            if (this.zipcode.length == 9) {
                return false
            }

            return true
        },

        handleZipcodeChange() {
            
            if (this.zipcode.length == 9) {

                this.loading = true

                loadZipcode(this.zipcode)
                .then((res => {

                    const address = res.data.data
                    console.log(address);

                    this.address = address
                }))
                .finally(() => {
                    this.loading = false
                })
            }
        },

        search() {

            this.loading = true

            this.axios.get(DrugstoresURLs.SEARCH, {
                params: {
                    latitude: this.address.lat,
                    longitude: this.address.lng,
                    radius: 0.7
                },
                headers: {
                    "Transaction-Key": "dpnQ8FYWeqm8aVYbgkr7UVQdAuCLFU8RZAwMvseHxBfsDXqzaV",
                }
            })
            .then((res) => {

                this.loading = false

                console.log(res.data.drugstores);
                this.venues = res.data.drugstores

                VueScrollTo.scrollTo('#results')
            })
        }
    },
}
</script>