<template>
    <div class="input-group">
        <label v-show="label">{{label}}</label>
        <slot />
    </div>
</template>

<script>
export default {
    props: ['label']
}
</script>

<style lang="scss" scoped>

$label-padding: 16px;

.input-group {
    position: relative;
    @apply border border-black p-5 my-4;
}

label {
    position: absolute;
    top: -14px;
    left: $label-padding;
    background-color: #FFFFFF;
    @apply text-gray-600 px-1 font-medium;
}

</style>