<template>
    <div>
        <Loading :loading="true" :opacity="1.0" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loading from '../../components/app/Loading'
import { API, CardURL } from "../../api"

const formatCpf = (stringCpf = '') => {
    var regex = /[.-\s]/g;
    var result = stringCpf.replace(regex, '');

    return result
}

export default {
    components: { Loading },

    mounted() {

        //https://develop.dd.meutem.com.br/v1/api-auth/login
        //http://pagbank-api.test/api/account/login
        this.axios.post('https://api.dd.meutem.com.br/v1/api-auth/login', { 
             'companyId': '1286918',
             'apiKey': 'SyQOihBKkK88zAW82OLJ'
        })
        .then((res) => {

            const tokenzeus = res.data.data.token

            this.axios.get('https://loja.meutem.com.br/clients/apiLoginPagsaude', { params: {
                "userToken": this.userToken,
                "zeusToken": tokenzeus
            }})
            .then((res) => {

                const user = res.data.response

                this.axios.get('https://loja.meutem.com.br/cliente/apiMeusDados', { params: {
                    "client_channel_id": user.client_channel_id,
                    "channel_id": user.channel_id
                }})
                .then((res) => {

                    const account = res.data;

                    this.$store.commit('account/setLogged', {
                        tokenzeus,
                        loggedUser: {
                            id: user.client_channel_id,
                            name: user.client_channel_name,
                            gender: user.client_channel_gender,
                            birthDate: account.cliente.birthdate,
                            email: account.cliente.email,
                            phone: account.cliente.telephone_1,
                            cpf: formatCpf(account.cliente.cpf),
                        }
                    })
                    // this.$store.commit('account/setLogged', {
                    //     tokenzeus,
                    //     loggedUser: {
                    //         id: user.client_channel_id,
                    //         name: user.client_channel_name,
                    //         gender: user.client_channel_gender,
                    //         birthDate: account.cliente.birthdate,
                    //         email: account.cliente.email,
                    //         phone: account.cliente.telephone_1,
                    //         cardNumber: account.card.number,
                    //         cpf: formatCpf(account.cliente.cpf),
                    //         luckNumber: account.numero_sorte
                    //     }
                    // })

                    this.$router.push({ name: 'home' })
                })
                .catch(this.handleLoginFailed)

                API.get(CardURL(user.client_channel_id, this.$store.getters['account/onixCode']), {
                    headers: {
                        "Transaction-Key": "njRRmvsxpZyUbU2fUsdbppEaMeNkLLARkNQg44Wks7rbscHNmL",
                    },
                }).then((res) => {

                    const cardData = res.data;
                    if(cardData.length > 0){

                        this.$store.commit('account/setLogged', {
                            tokenzeus,
                            loggedUser: {
                                cardNumber: cardData[0].number,
                                emitter: cardData[0].emitter,
                                validator: cardData[0].validator,
                                product: cardData[0].product,
                                way: cardData[0].way,
                            }
                        })

                        console.log(this.$store)
                    }else {
                        throw new Error("Cartão não encontrado")
                    }

                })
                .catch(this.handleLoginFailed)
            })
            .catch(this.handleLoginFailed)
        })
        .catch(this.handleLoginFailed)
    },

    methods: {

        handleLoginFailed(err) {
            console.error(err)
            this.$store.dispatch('account/resetUserToken')
            this.$router.push({ name: 'login.error' })
        }
    },

    computed: {

        ...mapGetters('account', ['userToken'])
    }
}
</script>