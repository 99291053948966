<template>
    <Page back-to="appointment.exams" title="SOLICITAÇÃO PARA AGENDAMENTO DE EXAMES" :steps="4" :current-step="2">

        <h4 class="navigation-subtitle">Anexe pedido médico</h4>

        <h4 class="font-bold text-gray-500 text-left mt-8 text-lg">Anexe ou tire um foto do pedido médico:</h4>
        <h4 class="font-medium text-gray-500 text-left mb-6 italic text-sm">(Os formatos aceitos são: PDF, JPG e PNG)</h4>

        <div v-if="hasFile">
            <div class="file-item">
            <div class="file-name flex-shrink-1 flex-grow-1">{{ file.file.name }}</div>
                <button class="flex-shrink-0 flex-grow-0" @click="removeUpload"><img src="../../../assets/remove.png" class="icon"></button>
            </div>
        </div>

        <form class="mt-16" v-on:submit="submitForm">

            <button class="btn btn-blue btn-lg" @click="pickFile" type="button">ANEXAR ARQUIVO</button>

            <h4 class="navigation-subtitle my-6 text-gray-500">OU</h4>
            
            <button class="btn btn-blue btn-lg mb-12" @click="pickPhoto" type="button">TIRAR FOTO</button>

            <h4 class="font-bold text-dark text-center text-lg">ATENÇÃO:</h4>

            <h4 class="font-bold text-dark text-left leading-5 mb-10">
                A foto ou arquivo precisam estar legíveis e ser de uma solicitação médica.
                <br>
                É imprescindível conter a assinatura do médico com carimbo e CRM.
            </h4>
            
            <button class="btn btn-blue btn-lg mb-12" type="submit">
                PRÓXIMO
            </button>

            <input id="fileUpload" type="file" hidden @change="fileUploadChanged" accept=".pdf,.jpg,.jpeg,.png">
            <input id="photoUpload" type="file" accept="image/*" capture="camera" hidden @change="fileUploadChanged">
        </form>
    </Page>
</template>

<script>
import Page from '../../../components/app/Page'

export default {
    components: { Page },
    data: () => ({ file: undefined }),
    computed: {
        hasFile() {
            return this.file && this.file.file.name
        }
    },
    methods: {
        submitForm(e){
            e.preventDefault();
            if(this.file !== undefined){
                this.$router.push({ name: 'appointment.exams.schedule' })
            } else{
                this.$toasted.show('Anexe pelo menos um arquivo para prosseguir', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
            }
        },
        pickFile() {
            document.getElementById("fileUpload").click()
        },

        pickPhoto() {
            document.getElementById("photoUpload").click()
        },

        fileUploadChanged(event) {
            const file = event.target.files[0]
            this.$store.commit('appointments/updateFile', { 
                file: {
                    name: file.name
                },
                fileObject: file
            });
            this.file = { 
                file: {
                    name: file.name
                },
                fileObject: file
            };
        },
        removeUpload() {
            this.file = undefined
        }
    }
}
</script>

<style lang="scss" scoped>

    .file-item {
        background-color: #e6eff1;
        display: flex;
        padding: .75rem 1.5rem;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-style: italic;
        border-radius: .25rem;

        .file-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 1rem;
        }

        .icon {
            width: 30px;
        }
    }

</style>