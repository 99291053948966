<template>
    <div class="app-container">

        <div class="w-100 flex justify-center mt-20 mb-10">
            <img class="success" src="../../assets/pagbank-success.png" alt="Sucesso">
        </div>

        <h1 class="navigation-title">RECEBEMOS SUA SOLICITAÇÃO</h1>

        <h1 class="text-gray-500 font-bold mt-10 mb-20 text-lg px-14">{{ successMessage }}</h1>

        <router-link :to="{ name: 'home' }"><button class="btn btn-blue btn-lg mb-16">ENTENDI</button></router-link>
    </div>
</template>

<script>
export default {
    props: [ 'message' ],

    computed: {

        successMessage() {

            return this.message || 'Em breve nossa equipe entrará em contato com você por e-mail ou WhatsApp.'
        }
    }
}
</script>

<style lang="scss" scoped>
    img.success {
        max-width: 150px;
        max-height: 150px;
    }
</style>