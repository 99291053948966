<template>
    <div class="relative flex flex-col bg-gray-900 px-8 py-8 text-white text-left">
        <a class="underline mb-3" target="_blank" href="https://mkt.dd.meutem.com.br/wp-content/uploads/2020/10/Politica_Privacidade_15102020.pdf">REGRAS E REGULAMENTOS</a>
        <router-link :to="{ name: 'lucknumber' }" class="underline mb-3">NÚMERO DA SORTE</router-link>

        <p class="text-sm mt-4">PagBank Saúde é administrado pela TEM Saúde.</p>
        <p class="text-sm mt-4">Fale com nossa Central de Atendimento</p>
        <p class="text-sm">Segunda a sexta das 7h às 19h</p>
        <p class="text-sm">* Exceto feriados nacionais</p>
        <p class="text-sm">4003-1775 para capitais e regiões metropolitanas ou</p>
        <p class="text-sm">0800-728-2174 para demais regiões</p>

        <p class="text-sm mt-4">TEM Administradora de cartões SA</p>
        <p class="text-sm">CNPJ: 09.216.007/0001-10</p>
        <p class="text-sm">TEM SAÚDE é um programa de saúde preventiva particular. TEM SAÚDE não é seguro ou plano de saúde</p>

        <a :href="linkURL" target="_blank">
            <div class="whatsapp-support">
                <img src="../../assets/chat.png">
            </div>
        </a>
    </div>
</template>

<script>
import whatsapp from '../../utils/whatsapp'

export default {
    
    computed: {

        linkURL() {
            return whatsapp.WHATSAPP_URL
        }
    }
}
</script>

<style lang="scss" scoped>

$whatsapp-icon-size: 60px;
$whatsapp-icon-padding: 20px;
$whatsapp-icon-container-size: #{$whatsapp-icon-size + ($whatsapp-icon-padding * 2)};

.whatsapp-support {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $whatsapp-icon-container-size;
    height: $whatsapp-icon-container-size;
    border-radius: 50%;
    position: absolute;
    right: 6px;
    top: calc(-#{$whatsapp-icon-container-size} / 2);
    @apply bg-blue;

    img {
        width: $whatsapp-icon-size;
        height: $whatsapp-icon-size;
    }
}

</style>