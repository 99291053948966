<template>
    <div class="main">
        <div v-if="loggedUser" class="text-left px-4 py-2 font-bold">OLÁ {{ userName }}!</div>
        <div class="flex flex-col items-center py-6 px-20 hero">
            <div class="mb-2">
                <img src="../assets/pagbank-hero.png" alt="Hero" />
            </div>
            <p class="font-bold text-center text-gray-900 text-xl">Solicite aqui suas consultas ou exames e acompanhe seus agendamentos:</p>
        </div>
        <div class="hero pt-2">

            <div class="main rounded-t-3xl p-16 pb-8 flex flex-col items-center">
                
                <div class="flex flex-row justify-center">
                    <router-link :to="{name: 'appointment.inperson'}">
                        <div class="menu-item m-2 pb-4">
                            <div class="menu-item-icon">
                                <img src="../assets/appointments.jpeg">
                            </div>
                            <p class="text-xs">CONSULTAS</p>
                        </div>
                    </router-link>
                    
                    <router-link :to="{name: 'appointment.video'}">
                        <div class="menu-item m-2 pb-3">
                            <div class="menu-item-icon">
                                <img src="../assets/video.jpeg">
                            </div>
                            <p class="text-xs leading-3">CONSULTAS POR VÍDEO</p>
                        </div>
                    </router-link>
                </div>

                <div class="flex flex-row justify-center">

                    <router-link :to="{name: 'appointment.exams'}">
                        <div class="menu-item m-2 pb-4">
                            <div class="menu-item-icon">
                                <img src="../assets/exams.jpeg">
                            </div>
                            <p class="text-xs leading-3">EXAMES E VACINAS</p>
                        </div>
                    </router-link>

                    <router-link :to="{name: 'construction'}">
                        <div class="menu-item m-2 pb-4">
                            <div class="menu-item-icon">
                                <img src="../assets/schedule.jpeg">
                            </div>
                            <p class="text-xs leading-3">MEUS AGENDAMENTOS</p>
                        </div>
                    </router-link>
                </div>

                <div class="my-6">
                    <h1 class="font-bold text-xl">Encontre farmácias e economize:</h1>
                </div>

                <div class="flex flex-row justify-center">

                    <router-link :to="{name: 'drugstores'}">
                        <div class="menu-item m-2 pb-4">
                            <div class="menu-item-icon">
                                <img src="../assets/drugstores.jpeg">
                            </div>
                            <p class="text-xs">FARMÁCIAS</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>


        <div class="main pt-2">
            
            <div class="bg-avocado rounded-t-3xl p-16 pt-10 flex flex-col items-center">
                
                <p class="font-bold text-center text-gray-900 text-xl mb-6">Administre sua conta PagBank Saúde:</p>

                <div class="flex flex-row justify-center">

                    <!--<router-link :to="{name: 'account.statement'}">-->
                    <router-link :to="{name: 'construction'}">
                        <div class="menu-item m-2 pb-4">
                            <div class="menu-item-icon">
                                <img src="../assets/statement.jpeg">
                            </div>
                            <p class="text-xs">EXTRATO</p>
                        </div>
                    </router-link>

                    <router-link :to="{name: 'account.mycard'}">
                        <div class="menu-item m-2 pb-3">
                            <div class="menu-item-icon">
                                <img src="../assets/card.jpeg">
                            </div>
                            <p class="text-xs leading-3">MEU CARTÃO PAGBANK SAÚDE</p>
                        </div>
                    </router-link>
                </div>

                <div class="flex flex-row justify-center">

                    <router-link :to="{name: 'account.venues'}">
                        <div class="menu-item m-2 pb-3">
                            <div class="menu-item-icon">
                                <img src="../assets/search.jpeg">
                            </div>
                            <p class="text-xs">BUSCAR REDE CREDENCIADA</p>
                        </div>
                    </router-link>

                    <router-link :to="{name: 'account.dependents'}">
                        <div class="menu-item m-2 pb-4">
                            <div class="menu-item-icon">
                                <img src="../assets/dependents.jpeg">
                            </div>
                            <p class="text-xs leading-3">DEPENDENTES</p>
                        </div>
                    </router-link>
                </div>

                <div class="flex flex-row justify-center">

                    <!--<router-link :to="{name: 'account.profile.edit'}">-->
                    <router-link :to="{name: 'construction'}">
                        <div class="menu-item m-2 pb-3">
                            <div class="menu-item-icon">
                                <img src="../assets/profile.jpeg">
                            </div>
                            <p class="text-xs">ALTERAR DADOS</p>
                        </div>
                    </router-link>

                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('account', ['loggedUser']),

        userName() {

            return this.loggedUser.name
        }
    }
}
</script>

<style lang="scss" scoped>
.main {
    background-color: #F7F7F7;
}

.hero {
    background-color: #CCE5E4;

    img { 
        width: 320px;
    }
}
</style>