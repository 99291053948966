<template>
    <Page :back-to="backTo" :title="title" :steps="steps" :current-step="currentStep">

        <h4 class="navigation-subtitle">Escolha o melhor dia e horário</h4>

        <div class="mt-8">

            <h1 class="font-medium text-lg mb-6 text-gray-800">Selecione um ou mais dias de sua preferência:</h1>
            
            <div class="options">
                <button id="mon" class="option" :class="{ active: this.days.mon }" @click="handleDayClick">SEG</button>
                <button id="tue" class="option" :class="{ active: this.days.tue }" @click="handleDayClick">TER</button>
                <button id="wed" class="option" :class="{ active: this.days.wed }" @click="handleDayClick">QUA</button>
            </div>

            <div class="options">
                <button id="thu" class="option" :class="{ active: this.days.thu }" @click="handleDayClick">QUI</button>
                <button id="fri" class="option" :class="{ active: this.days.fri }" @click="handleDayClick">SEX</button>
                <button id="sat" class="option" :class="{ active: this.days.sat }" @click="handleDayClick">SÁB</button>
            </div>

            <h1 class="font-medium text-lg mt-10 mb-6 text-gray-800">Selecione o período de sua preferência:</h1>

            <div class="options">
                <button id="morning" class="option" :class="{ active: this.times.morning }" @click="handleTimeClick">MANHÃ</button>
                <button id="afternoon" class="option" :class="{ active: this.times.afternoon }" @click="handleTimeClick">TARDE</button>
                <button id="evening" class="option" :class="{ active: this.times.evening }" @click="handleTimeClick">NOITE</button>
            </div>
            
            
            <button class="btn btn-blue btn-lg mb-12 mt-16" v-on:click="submit">PRÓXIMO</button>
            
        </div>
    </Page>
</template>

<script>
import Page from '../../components/app/Page'

export default {
    components: { Page },

    props: [ 'title', 'steps', 'currentStep', 'backTo', 'nextRoute' ],

    computed: {
        
        days() {
            return this.$store.getters['appointments/schedule'].days
        },

        times() {
            return this.$store.getters['appointments/schedule'].times
        },

        routeTo() {

            if (typeof this.nextRoute === 'object') {
                return this.nextRoute
            }
            else {
                return { name: this.nextRoute }
            }
        }
   },

   mounted() {
   },

   methods: {

        submit(){
            let validDay, validTime = false;
            for( let day in this.days){
                this.days[day] && (validDay=true);
            }

            for( let time in this.times){
                this.times[time] && validDay && (validTime=true);
            }
            
            if(!validDay || !validTime){
                this.$toasted.show('Preencha as informações para prosseguir', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
            } else{
                this.$router.push(typeof this.nextRoute === 'object' ? this.nextRoute : { name:  this.nextRoute })
            }
            
        },
        handleDayClick(event) {

            const isActive = event.target.classList.contains('active')
            const id = event.target.id

            this.$store.commit('appointments/updateScheduleDay', {day: id, selected: !isActive})
        },

        handleTimeClick(event) {

            const isActive = event.target.classList.contains('active')
            const id = event.target.id

            this.$store.commit('appointments/updateScheduleTime', {time: id, selected: !isActive})
        }
    }
}
</script>

<style lang="scss" scoped>
.options {
    display: flex;
    @apply mb-6;

    .option {
        margin: 0 10px;
        flex-grow: 1;
        @apply border border-blue rounded-lg text-center text-lg px-4 py-2 font-bold text-gray-500;

        &.active {
            @apply text-white bg-blue;
        }
    }
}
</style>