import Login from "../pages/Login"
import LoginError from "../pages/Login/LoginError"
import Home from "../pages/Home"
import Appointment from "../pages/Appointment"
import InPersonHome from "../pages/Appointment/InPerson/Home"
import InPersonConfirm from "../pages/Appointment/InPerson/Confirm"
import Speciality from "../pages/Appointment/InPerson/Speciality"
import VideoHome from "../pages/Appointment/Video/Home"
import VideoConfirm from "../pages/Appointment/Video/Confirm"
import ExamsHome from "../pages/Appointment/Exams/Home"
import ExamsUpload from "../pages/Appointment/Exams/Upload"
import ExamsConfirm from "../pages/Appointment/Exams/Confirm"
import Dependents from "../pages/Appointment/Dependents"
import Schedule from "../pages/Appointment/Schedule"
import SchedulePickTime from "../pages/Appointment/PickTime"
import Success from "../pages/Appointment/Success"
import MySchedule from "../pages/MySchedule"
import MyScheduleDetails from "../pages/MySchedule/Details"
import Drugstores from "../pages/Drugstores"
import DependentsEdit from "../pages/Appointment/Dependents/Edit"
import AccountStatement from "../pages/Account/Statement"
import AccountMyCard from "../pages/Account/MyCard"
import AccountSearchVenues from "../pages/Account/SearchVenues"
import AccountEditProfile from "../pages/Account/EditProfile"
import LuckNumber from "../pages/LuckNumber"
import Construction from "../pages/Construction"

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      allowsUnlogged: true,
    },
  },
  {
    path: "/login/error",
    name: "login.error",
    component: LoginError,
    meta: {
      allowsUnlogged: true,
    },
  },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/appointment/inperson",
    component: Appointment,
    children: [
      {
        path: "",
        name: "appointment.inperson",
        component: InPersonHome,
      },
      {
        path: "/appointment/inperson/dependents",
        name: "appointment.inperson.dependents",
        component: Dependents,
        props: { backTo: "appointment.inperson" },
      },
      {
        path: "/appointment/inperson/speciality",
        name: "appointment.inperson.speciality",
        component: Speciality,
      },
      {
        path: "/appointment/inperson/schedule",
        name: "appointment.inperson.schedule",
        component: Schedule,
        props: {
          title: "SOLICITAÇÃO PARA AGENDAMENTO DE CONSULTAS",
          steps: 4,
          currentStep: 3,
          backTo: "appointment.inperson.speciality",
          nextRoute: "appointment.inperson.confirm",
        },
      },
      {
        path: "/appointment/inperson/confirm",
        name: "appointment.inperson.confirm",
        component: InPersonConfirm,
      },
      {
        path: "/appointment/inperson/success",
        name: "appointment.inperson.success",
        component: Success,
        props: true,
      },
    ],
  },
  {
    // TODO - Tele Medicina é igual consulta normal, entretanto vai com descrição: "TELEMEDICINA"
    path: "/appointment/video",
    component: Appointment,
    children: [
      {
        path: "",
        name: "appointment.video",
        component: VideoHome,
      },
      {
        path: "/appointment/video/dependents",
        name: "appointment.video.dependents",
        component: Dependents,
        props: { backTo: "appointment.video" },
      },
      {
        path: "/appointment/video/schedule",
        name: "appointment.video.schedule",
        component: Schedule,
        props: {
          title: "SOLICITAÇÃO PARA AGENDAMENTO DE TELEMEDICINA",
          steps: 3,
          currentStep: 2,
          backTo: "appointment.video",
          nextRoute: {
            name: "appointment.video.confirm",
            params: { backTo: "appointment.video.schedule" },
          },
        },
      },
      {
        path: "/appointment/video/pick-time",
        name: "appointment.video.picktime",
        component: SchedulePickTime,
        props: {
          title: "SOLICITAÇÃO PARA AGENDAMENTO DE TELEMEDICINA",
          steps: 3,
          currentStep: 2,
          backTo: "appointment.video",
          nextRoute: {
            name: "appointment.video.confirm",
            params: { backTo: "appointment.video.picktime" },
          },
        },
      },
      {
        path: "/appointment/video/confirm",
        name: "appointment.video.confirm",
        component: VideoConfirm,
        props: true,
      },
      {
        path: "/appointment/video/success",
        name: "appointment.video.success",
        component: Success,
        props: true,
      },
    ],
  },
  {
    path: "/appointment/exams",
    component: Appointment,
    children: [
      {
        path: "",
        name: "appointment.exams",
        component: ExamsHome,
      },
      {
        path: "/appointment/exams/upload",
        name: "appointment.exams.upload",
        component: ExamsUpload,
      },
      {
        path: "/appointment/exams/schedule",
        name: "appointment.exams.schedule",
        component: Schedule,
        props: {
          title: "SOLICITAÇÃO PARA AGENDAMENTO DE EXAMES",
          steps: 4,
          currentStep: 3,
          backTo: "appointment.exams.upload",
          nextRoute: "appointment.exams.confirm",
        },
      },
      {
        path: "/appointment/exams/confirm",
        name: "appointment.exams.confirm",
        component: ExamsConfirm,
      },
      {
        path: "/appointment/exams/success",
        name: "appointment.exams.success",
        component: Success,
        props: true,
      },
    ],
  },
  {
    path: "/schedule",
    name: "schedule",
    component: MySchedule,
  },
  {
    path: "/construction",
    name: "construction",
    component: Construction,
  },
  {
    path: "/schedule/details",
    name: "schedule.details",
    component: MyScheduleDetails,
    props: true,
  },
  {
    path: "/drugstores",
    name: "drugstores",
    component: Drugstores,
  },
  {
    path: "/dependents",
    name: "dependents",
    component: Dependents,
    props: true,
  },
  {
    path: "/dependents/create",
    name: "dependents.create",
    component: DependentsEdit,
    props: true,
  },
  {
    path: "/account/statement",
    name: "account.statement",
    component: AccountStatement,
  },
  {
    path: "/account/venues",
    name: "account.venues",
    component: AccountSearchVenues,
  },
  {
    path: "/account/mycard",
    name: "account.mycard",
    component: AccountMyCard,
    props: { backTo: "home" },
  },
  {
    path: "/account/dependents",
    name: "account.dependents",
    component: Dependents,
    props: { backTo: "home" },
  },
  {
    path: "/account/profile/edit",
    name: "account.profile.edit",
    component: AccountEditProfile,
    props: { backTo: "home" },
  },
  {
    path: "/luck-number",
    name: "lucknumber",
    component: LuckNumber,
  },
]

export default routes
