<template>
    <div>
        <InputGroup :label="label">
            <InputMask v-if="mask" :placeholder="placeholder" :mask="mask" maskChar="" :value="value" @input="$emit('input', $event)" @blur="$emit('blur')" :required="required"/>
            <input v-else :placeholder="placeholder" :value="value" @input="$emit('input', $event.target.value)" @blur="emit('blur')" :required="required">
        </InputGroup>
    </div>
</template>

<script>
import InputGroup from './InputGroup'
import InputMask from 'vue-input-mask';

export default {
    components: { InputGroup, InputMask },

    props: [ 'label', 'placeholder', 'mask', 'value', 'required']
}
</script>
