<template>
  <div id="app">
    <Notifications />
    <vue-confirm-dialog></vue-confirm-dialog>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Notifications from './components/app/Notifications'
import Header from './components/app/Header'
import Footer from './components/app/Footer'

export default {
  name: 'App',

  components: { Notifications, Header, Footer },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
