<template>
    <Page title="BUSCAR REDE CREDENCIADA" backTo="home" :loading="loading">

        <div class="my-16"><Input name="zipcode" label="Digite o seu CEP:" mask="99999-999" placeholder="00000-000" v-model="zipcode" @input="handleZipcodeChange" /></div>

        <div class="mb-16"><Input name="city" label="Nome da Rua / Avenida:" placeholder="Nome da Rua" v-model="address.route" /></div>

        <button class="btn btn-blue btn-lg mb-12" @click="search">BUSCAR</button>

        <div class="mt-10" id="results">
            <div v-for="(venue, index) in venues" :key="index" class="rounded-lg shadow mb-10 px-4">

                <div class="text-center font-bold text-xl py-5 px-3 border-b text-gray-500">
                    {{ venue.nome }}
                </div>
                <div class="text-left px-2 py-6">
                    <!-- <h4 class="font-bold mb-1">Endereço:</h4> -->
                    <p class="text-gray-500 font-bold fond-medium leading-5">
                        {{ venue.especialidade }}
                    </p> <br>
                    <p class="text-gray-500 fond-medium leading-5">
                        {{ venue.municipio }} - {{ venue.uf }}
                        <!-- {{ venue.address }} {{ venue.number }}, {{ venue.neighborhood }}, {{ venue.city }} - {{ venue.state }} -->
                        <!-- <br> -->
                        <!-- CEP {{ venue.postal_code }} -->
                    </p>

                    <div v-if="venue.telephone" class="mt-4">
                        <h4 class="font-bold mb-1">Telefone</h4>
                        <p class="text-gray-500 fond-medium leading-5">{{ venue.telephone }}</p>
                    </div>
                </div>
            </div>
        </div>

    </Page>
</template>

<script>
import Page from '../../components/app/Page'
import Input from '../../components/ui/form/Input'
import { loadZipcode } from '../../helpers'
import { AccountsURLs } from '../../api'
import qs from 'qs'

const VueScrollTo = require('vue-scrollto')

export default {
    components: { Page, Input },

    data() {
        return {
            loading: false,
            zipcode: '',
            address: '',
            venues: []
        }
    },

    methods: {

        handleZipcodeChange() {
            
            if (this.zipcode.length == 9) {

                this.loading = true

                loadZipcode(this.zipcode)
                .then((res => {

                    const address = res.data.data
                    this.address = address

                    console.log(this.address);
                }))
                .finally(() => {
                    this.loading = false
                })
            }
        },

        search() {

            this.loading = true

            this.axios({
                method: 'post',
                url: AccountsURLs.VENUES_SEARCH,
                data: qs.stringify({
                    state: this.address.uf,
                    city: this.address.city,
                    // neighborhood: this.address.sublocality
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            })
            .then((res) => {

                this.loading = false

                console.log(res);
                this.venues = res.data.data

                VueScrollTo.scrollTo('#results')
            })
        }
    },
}
</script>