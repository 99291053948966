<template>
    <Page :back-to="backTo" :title="title" :steps="steps" :current-step="currentStep">

        <h4 class="navigation-subtitle">Escolha o horário</h4>

        <div class="mb-20 flex justify-center">
            <VuePersianDatetimePicker 
                v-model="time" 
                type="time" 
                locale="pt-br"
                :locale-config="localeConfig"
                :jumpMinute="15" 
                :roundMinute="true" 
                :initial-value="time"
                @change="onChange"
            >
            
                <template v-slot:submit-btn="{ canSubmit, submit, lang }">
                    
                    <button
                        type="button"
                        class="btn btn-blue btn-lg"                    
                        :disabled="!canSubmit"
                        @click="submit"
                        v-text="lang.submit"
                    />
                </template>
            
            </VuePersianDatetimePicker>
        </div>
        
    </Page>
</template>

<script>
import Page from '../../components/app/Page'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';

export default {
    components: { Page, VuePersianDatetimePicker },

    props: [ 'title', 'steps', 'currentStep', 'backTo', 'nextRoute' ],

    data() {
        return {
            time: this.$store.getters['appointments/scheduledDayTime'],

            localeConfig: {
                'pt-br': {
                    lang: {
                        submit: 'PRÓXIMO'
                    }
                }
            }
        }
    },

    // mounted() {
    //     console.log(this.nextRoute);
    // },

    methods: {

        onChange() {
            this.$store.commit('appointments/updateScheduleDayTime', this.time)

            console.log(this.nextRoute);
            this.$router.push( this.nextRoute )
        }
    }
}
</script>

<style lang="scss">

.vpd-input-group, .vpd-header {
    display: none !important;
}

.vpd-addon-list {
    border: none !important;
}

.vpd-addon-list {
    bottom: unset !important;
}

.vpd-actions {
    
    height: 70px !important;
    padding: 0 !important;

    button {
        height: 70px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.vpd-time .down-arrow-btn, .vpd-time .up-arrow-btn {

    display: flex !important;
    justify-content: center !important;
}

</style>