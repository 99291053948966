<template>
    <div>
        <AppointmentCard v-for="(appointment, index) in appointments" :key="index" :appointment="appointment" />
    </div>
</template>

<script>
import AppointmentCard from './Card'

export default {
    components: { AppointmentCard },

    props: ['listType'],

    data() {
        return {

            open: [
                {
                    name: 'Lais Faria Uilan',
                    speciality: 'ORTOPEDISTA',
                    date: 'TER - TARDE',
                    venue: {
                        name: 'POLICLINIC BRASIL',
                        address: 'AV. BRASIL, 1000 - JD. AMÉRICA',
                        city: 'SÃO PAULO',
                        state: 'SP'
                    }
                },
                {
                    name: 'Lais Faria Uilan',
                    speciality: 'ORTOPEDISTA',
                    date: 'QUA - TARDE',
                    venue: {
                        name: 'POLICLINIC BRASIL',
                        address: 'AV. BRASIL, 1000 - JD. AMÉRICA',
                        city: 'SÃO PAULO',
                        state: 'SP'
                    }
                }
            ],

            closed: [
                {
                    name: 'Lais Faria Uilan',
                    speciality: 'ALERGISTA',
                    date: 'SEX - MANHÃ',
                    venue: {
                        name: 'POLICLINIC BRASIL',
                        address: 'AV. BRASIL, 1000 - JD. AMÉRICA',
                        city: 'SÃO PAULO',
                        state: 'SP'
                    }
                }
            ]
        }
    },

    computed: {

        appointments() {

            return this.listType == 0 ? this.open : this.closed
        }
    }
}
</script>