<template>
    <Page title="HISTÓRICO DE UTILIZAÇÃO" backTo="home">
        <div class="border-b"></div>
        
        <div class="py-6 mb-10">
            <div class="statement-card py-1 flex" v-for="(item, index) in items" :key="index">
                <div class="mr-5 flex flex-col">
                    <img class="icon" src="../../assets/card.jpeg">
                    <div class="icon-line"></div>
                </div>
                <div class="text-left font-bold pb-8 flex-grow">
                    <div class="flex justify-between">
                        <div>{{ item.transactionType }}</div>
                        <div class="text-gray-500">{{ item.date }}</div>
                    </div>
                    <div v-if="item.venue" class="font-bold text-xl mb-0 mt-2">
                        {{ item.venue }}
                    </div>
                    <div class="font-bold text-lg">
                        {{ item.amount }}
                    </div>
                </div>
            </div>
        </div>
        
    </Page>
</template>

<script>
import Page from '../../components/app/Page'

export default {
    components: { Page },

    data() {
        return {
            items: [
                {
                    transactionType: 'Débito',
                    date: '05/09',
                    venue: 'Policlinic Brasil',
                    amount: 'R$ 89,00'
                },
                {
                    transactionType: 'Crédito via Cartão',
                    date: '30/08',
                    amount: 'R$ 125,00'
                },
                {
                    transactionType: 'Débito',
                    date: '10/06',
                    venue: 'Policlinic Brasil',
                    amount: 'R$ 89,00'
                }
            ]
        }
    }

}
</script>

<style lang="scss" scoped>
.icon {
    max-width: 24px;
}

.statement-card:not(:last-child) {

    .icon-line {
    position: relative;
    flex-grow: 1;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        @apply bg-green;
    }
}
}

.icon-line {
    position: relative;
    flex-grow: 1;

    
}
</style>