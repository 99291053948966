<template>
    <Page title="MEUS AGENDAMENTOS" backTo="schedule" :loading="loading">

        <div v-if="appointment" class="my-16 text-left">

            <h1 class="font-bold text-gray-500 text-2xl">{{ appointment.venue.name }}</h1>
            <h1 class="font-bold text-gray-500 text-sm">{{ appointment.venue.address }}</h1>
            <h1 class="font-bold text-gray-500 text-sm">{{ appointment.venue.city }} - {{ appointment.venue.state }}</h1>

            <hr class="my-4">

            <h1 class="font-bold text-gray-500 text-2xl my-6">{{ appointment.name }}</h1>
            
            <div class="flex justify-between">
                <div class="font-bold text-gray-500">CONSULTA</div>
                <div class="font-bold text-gray-500">DATA</div>
            </div>

            <div class="flex justify-between mt-2">
                <div class="font-bold text-dark">{{ appointment.speciality }}</div>
                <div class="font-bold text-dark">{{ appointment.date }}</div>
            </div>

            <div class="status-card mt-6">
                Aguardando confirmação
            </div>

            <hr class="my-6">

            <button class="btn btn-blue btn-lg mb-12 mt-10" @click="cancel">CANCELAR SOLICITAÇÃO</button>
        </div>
    </Page>
</template>

<script>
import Page from '../../components/app/Page'

export default {
    components: { Page },

    props: [ 'appointment' ],

    data() {
        return {
            loading: false
        }
    },

    methods: {

        cancel() {

            this.loading = true

            setTimeout(() => {
                this.loading = false
                this.$router.push({name: 'schedule'})
            }, 2000)
        }
    }

}
</script>

<style lang="scss" scoped>
.status-card {
    background-color: #E8EFF0;
    padding: 1rem;
    text-align: center;
    border-radius: 2px;
    color: #2F79BD;
    @apply font-bold;
}
</style>