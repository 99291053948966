<template>
    <Page title="NÚMERO DA SORTE" backTo="home">
        <div class="border-b"></div>
        
        <div class="py-6 font-bold text-lg">ESSE É SEU NÚMERO DA SORTE</div>
        
        <div class="py-8 mb-8 text-3xl font-bold text-gray-500">
            {{ loggedUser.luckNumber }}
        </div>

        <p class="text-left mb-10">
            (**) Valor bruto sorteado pela loteria federal,
            sem custo adicional. O cliente titular 
            participará em 1 sorteio no último sábado 
            do mês subsequente ao da aquisição do 
            Pagbank Saúde no valor de R$ 20.000
            (menos 25% de IR). Os sorteios desta
            promoção são administrados pela Icatu
            Capitalização 0800 286 0109 - e processo
            SUSEP 15414.900577/2019-47. É proibida a
            venda de titulo de capitalização a menores
            de 16 anos (Código Civil). A aprovação deste
            título pela SUSEP não significa incentivo ou
            recomendação à sua aquisição,
            representando exclusivamente sua
            adequação às normas em vigor. Para
            maiores informações consulte o APP no 
            "Como funciona"
        </p>

    </Page>
</template>

<script>
import { mapGetters } from 'vuex'
import Page from '../components/app/Page'

export default {
    components: { Page },

    computed: {

        ...mapGetters('account', ['loggedUser'])
    }

}
</script>

<style lang="scss" scoped>
.icon {
    max-width: 24px;
}

.statement-card:not(:last-child) {

    .icon-line {
    position: relative;
    flex-grow: 1;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        @apply bg-green;
    }
}
}

.icon-line {
    position: relative;
    flex-grow: 1;

    
}
</style>