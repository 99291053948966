<template>
    <Page :back-to="backTo" :loading="loading">
        <div class="app-container">
            <h1 class="navigation-title my-5">DEPENDENTES</h1>

            <div class="mb-16"><Input name="name" label="Nome:" placeholder="Nome" v-model="name" /></div>

            <div class="mb-16"><Input name="cpf" label="CPF:" mask="999.999.999-99" placeholder="000.000.000-00" v-model="cpf" /></div>

            <div class="mb-16"><Input name="birthDate" label="Data Nascimento:" mask="99/99/9999" placeholder="00/00/0000" v-model="birthDate" /></div>

            <button class="btn btn-blue btn-lg mb-12" @click="save" :disabled="isDisabled()">SALVAR</button>
        </div>
    </Page>
</template>

<script>
import Page from '../../../components/app/Page'
import Input from '../../../components/ui/form/Input'
import { API, AccountsURLs } from '../../../api'
import ServerErrorException from '../../../exceptions/ServerErrorException'
import { datesMixin } from '../../../mixins/dates'
import formatCpf from '../../../utils/formatCpf'
import { validate } from 'gerador-validador-cpf'
import { isValid } from 'date-fns'

export default {
    components: { Page, Input },

    mixins: [ datesMixin ],

    props: [ 'backTo' ],

    data() {
        return {
            loading: false,
            name: this.dependent ? this.dependent.NOME_DEPENDENTE : '',
            cpf: this.dependent ? this.dependent.CPF_DEPENDENTE : '',
            birthDate: '',
        }
    },

    computed: {
        
        successText() {

            return this.dependent ? "Dependente salvo com sucesso!" : "Dependente adicionado com sucesso!"
        }
    },

    methods: {

        isDisabled() {
            const date = new Date(this.birthDate.split('/')[2], parseInt(this.birthDate.split('/')[1]) - 1, this.birthDate.split('/')[0]);
            if((date.getFullYear() > new Date().getFullYear()) || (!isValid(date)))
                return true
            
            if(this.name.length < 4){
                return true
            }
            if(!validate(this.cpf)){
                return true
            } else {
                if(formatCpf(this.cpf) === this.$store.getters['account/loggedUser'].cpf){
                    this.$toasted.show('Não é possível cadastrar o Títular como dependente', { position: 'bottom-center', type: 'error', duration: 2500, fullWidth: true,})
                    return true
                }
            }if(this.birthDate.length < 10){
                return true
            }
            return false
        },

        save() {

            this.loading = true

            const cnpjPagSeguro = "08561701000101"

            const data = {
                "tokenzeus": this.$store.getters['account/tokenzeus'],
                "data_nascimento": this.rawDate(this.birthDate),
                "cpfTitular": this.$store.getters['account/loggedUser'].cpf,
                "cpf": this.cpf,
                "cnpj": cnpjPagSeguro,
                "Nome": this.name,
                "CodOnix": this.$store.getters['account/onixCode']
            }

           API.post(AccountsURLs.DEPENDENTS_ADHESION, data)
            .then((res) => {
                console.log(res.data);
                // this.$router.push(this.backTo)
            })
            .catch(err => {

                switch (err.constructor) {
                    // Case with blocks - https://eslint.org/docs/rules/no-case-declarations
                    case ServerErrorException: {
                        let msg = err.message + ''

                        if(msg.search('TokenZeus') > -1) {
                            this.$router.push({ name: "login" })
                        }
                        break;
                    }
                    default: {
                        console.error(err)
                        break;
                    }
                }
            })
            .finally(() => {

                this.$notify({
                    group: "success",
                    title: "Sucesso",
                    text: this.successText
                }, 3000);
                
                this.loading = false
                this.$router.push(this.backTo)
            })
        }
    }
}
</script>