<template>
    <div class="app-container">

        <div class="w-100 flex justify-center mt-20 mb-10">
            <img class="construc" src="../assets/construction.svg" alt="Ícone Em construção">
        </div>

        <h1 class="navigation-title">Em Breve</h1>

        <h1 class="text-gray-500 font-bold mt-10 mb-20 text-lg px-14">{{ constructionMessage }}</h1>

        <router-link :to="{ name: 'home' }"><button class="btn btn-blue btn-lg mb-16">VOLTAR</button></router-link>
    </div>
</template>

<script>
export default {
    props: [ 'message' ],

    computed: {

        constructionMessage() {

            return this.message || 'Estamos trabalhando nestes módulos para melhor atendê-lo. Em breve você terá uma jornada ainda melhor.'
        }
    }
}
</script>

<style lang="scss" scoped>
    img.construc {
        max-width: 150px;
        max-height: 150px;
    }
</style>