<template>
    <Page :back-to="backTo" :loading="loading">
        <div class="app-container">
            <h1 class="navigation-title my-5">DEPENDENTES</h1>

            <div v-for="dependent in dependents" :key="dependent.CPF_DEPENDENTE" class="shadow rounded-lg text-left my-8">
                <div class="border-b p-4 rounded-t-lg">
                    <p class="font-bold text-xl text-gray-500">{{ dependent.NOME_DEPENDENTE }}</p>
                </div>
                <div class="px-4 py-6 font-medium">
                    <p class="mb-3">CPF: {{ dependent.CPF_DEPENDENTE | cpf }}</p>
                    <p class="mb-3" v-show="false">Nascimento: 00/00/0000</p>
                </div>
                <div class="border-t rounded-b-lg">
                    <div class="flex justify-between">
                        <router-link :to="{ name: 'account.dependents.edit', params: { id: dependent.CPF_DEPENDENTE, dependent: dependent, backTo: { name: $route.name, params: { backTo: backTo } } } }" class="text-center flex-grow p-4 font-bold text-blue">Editar</router-link>
                        <div class="border-r"></div>
                        <button class="flex-grow p-4 font-bold text-blue" @click="deleteAction(dependent.CPF_DEPENDENTE)">Excluir</button>
                    </div>
                </div>
            </div>

            <router-link :to="{ name: 'dependents.create', params: { backTo: { name: $route.name, params: { backTo: backTo } } } }">
                <div class="btn btn-blue btn-lg mb-12 mt-12">ADICIONAR DEPENDENTE</div>
            </router-link>

        </div>
    </Page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import '../../../filters'
import Page from '../../../components/app/Page'

export default {
    components: { Page },

    props: [ 'backTo' ],

    data() {
        return {
            loading: false,
        }
    },

    mounted() {

        this.loadDependents()
    },

    computed: {

        ...mapGetters('dependents', ['dependents']),
     
        personalInfo() {
            return this.$store.state.personalInfo
        },

        address() {
            return this.personalInfo.district + ' - ' + this.personalInfo.city + ' - ' + this.personalInfo.state
        },

        speciality() {
            return this.$store.state.speciality
        },
    },

    methods: {

        ...mapActions('dependents', ['deleteDependent']),

        loadDependents() {

            this.loading = true

            this.$store.dispatch('dependents/loadDependents')
            .finally(() => {
                this.loading = false
            })
        },

        deleteAction(cpf) {

            this.$confirm({
                title: 'Excluir dependente',
                message: 'Deseja mesmo excluir este dependente?',
                button: {
                    no: 'Não',
                    yes: 'Sim'
                },
                callback: confirm => {
                    
                    if (confirm) {
                        
                        this.loading = true

                        this.deleteDependent(cpf)
                        .then(() => {

                            this.$notify({
                                group: 'success',
                                title: 'Successo',
                                text: 'Dependente excluído com sucesso!'
                            })

                            this.loadDependents()
                        })
                        .finally(() => {

                            this.loading = false
                        })
                    }
                }
            })
        }
    }
}
</script>