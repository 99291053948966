<template>
    <Page back-to="appointment.inperson" title="SOLICITAÇÃO PARA AGENDAMENTO DE CONSULTA" :steps="4" :current-step="2">
        <h4 class="navigation-subtitle">O que você quer agendar?</h4>

        <form class="mt-12" v-on:submit="submitForm">
            
            <Dropbox name="speciality" label="Selecione a especialidade:" :items="specialities" v-model="speciality" required="true" />
            
            <button class="btn btn-blue btn-lg mb-12" type="submit">
                PRÓXIMO
            </button>
        </form>
    </Page>
</template>

<script>
import { mapActions } from 'vuex'
import Page from '../../../components/app/Page'
import Dropbox from '../../../components/ui/form/Dropbox'

export default {
    components: { Page, Dropbox },

    data() {
        return {
            specialities: []
        }
    },

    mounted() {
        this.loadSpecialities()
        .then((res => {
            this.specialities = res.data.data.map ((speciality) => speciality.nome)
        }))
    },

    methods: {

        ...mapActions('appointments', ['loadSpecialities']),

        submitForm(e){
            e.preventDefault();
            this.$router.push({ name: 'appointment.inperson.schedule' })
        },
    },

    computed: {

        speciality: {
            get () {
                return this.$store.state.speciality
            },
            set (value) {
                this.$store.commit('appointments/updateSpeciality', value)
            }
        },
    }
}
</script>