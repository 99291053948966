<template>
    <div>
        <BackBar :to="{ name: 'home' }" />
        <div class="app-container mt-5">
            <h1 class="font-bold text-xl mb-2">MEUS AGENDAMENTOS</h1>
        </div>

        <div>
            <div class="tab">
                <button :class="{ active: selectedTab == 0}" @click="selectedTab = 0">SOLICITAÇÕES</button>
                <button :class="{ active: selectedTab == 1}" @click="selectedTab = 1">REALIZADOS</button>
            </div>
        </div>

        <div class="app-container my-5">
            <AppointmentList :listType="selectedTab" />
        </div>
    </div>
</template>

<script>
import BackBar from '../../components/app/BackBar'
import AppointmentList from '../../components/common/Appointments/List'

export default {
    components: { BackBar, AppointmentList },

    data() {
        return {
            selectedTab: 0
        }
    }
}
</script>

<style lang="scss" scoped>
.tab {
    display: flex;
    justify-content: space-between;

    button {
        flex-grow: 1;
        @apply font-medium py-2 bg-gray-100 border-b-2;

        &.active {
            @apply bg-white border-blue;
        }
    }
}
</style>