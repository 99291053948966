import { weekday, time } from "../../helpers"
import { API, AppointmentsURLs } from "../../api"
var FormData = require("form-data")

const state = {
  personalInfo: {
    dependent: "",
    zipcode: "",
    state: "",
    city: "",
    district: "",
  },

  speciality: "",

  schedule: {
    days: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    },
    times: {
      morning: false,
      afternoon: false,
      evening: false,
    },
    dayTime: "",
  },
}

const enumBestHour = {
  Tarde: "t",
  Manhã: "m",
  Noite: "n",
  "Qualquer Período": "q",
}

const mutations = {
  updateDependent(state, dependent) {
    state.personalInfo.dependent = dependent
  },

  updateZipcode(state, zipcode) {
    state.personalInfo.zipcode = zipcode
  },

  updateState(state, uf) {
    state.personalInfo.state = uf
  },

  updateCity(state, city) {
    state.personalInfo.city = city
  },

  updateDistrict(state, district) {
    state.personalInfo.district = district
  },

  updateSpeciality(state, speciality) {
    state.speciality = speciality
  },

  updateScheduleDay(state, { day, selected }) {
    state.schedule.dayTime = ""
    state.schedule.days[day] = selected
  },

  updateScheduleTime(state, { time, selected }) {
    state.schedule.dayTime = ""
    state.schedule.times[time] = selected
  },

  updateScheduleDayTime(state, dayTime) {
    state.schedule.dayTime = dayTime
  },

  updateFile(state, { file, fileObject }) {
    state.file = file
    state.fileObject = fileObject
  },
}

const getters = {
  personalInfo: (state) => state.personalInfo,

  addressText: (state) => {
    return (
      state.personalInfo.district +
      " - " +
      state.personalInfo.city +
      " - " +
      state.personalInfo.state
    )
  },

  speciality: (state) => state.speciality,

  schedule: (state) => state.schedule,

  file: (state) => state.file,

  scheduledDayTime: (state) => state.schedule.dayTime,

  scheduledTimes: (state) => {
    var times = ""
    var prefix = ""
    for (const [aTime, active] of Object.entries(state.schedule.times)) {
      if (active) {
        times += prefix + time(aTime)
        prefix = ", "
      }
    }
    return times
  },

  scheduledDays: (state) => {
    var times = ""
    var prefix = ""
    for (const [day, active] of Object.entries(state.schedule.days)) {
      if (active) {
        times += prefix + weekday(day)
        prefix = ", "
      }
    }
    return times
  },

  scheduledDaysJoined: (state) => {
    const weekdays = []
    for (const [key, value] of Object.entries(state.schedule.days)) {
      if (value) {
        weekdays.push(weekday(key))
      }
    }
    return weekdays.join(",")
  },

  scheduledTimesJoined: (state) => {
    const times = []
    for (const [key, value] of Object.entries(state.schedule.times)) {
      if (value) {
        times.push(time(key))
      }
    }
    return times.join(",")
  },

  hasFile: (state) => state.file != null,

  fileName: (state) => (state.file ? state.file.name : ""),

  fileObject: (state) => state.fileObject,
}

const actions = {
  loadSpecialities() {
    return API.get(AppointmentsURLs.SPECIALITIES)
  },

  createScheduleExam: ({ rootGetters, getters }) => {
    const user = rootGetters["account/loggedUser"]
    const personalInfo = rootGetters["appointments/personalInfo"]
    const speciality = rootGetters["appointments/speciality"]

    var data = new FormData()
    data.append("patient_name", user.name)
    data.append("cpf", user.cpf)
    data.append("patient_birthdate", user.birthDate)
    data.append("specialty", speciality)
    data.append("uf", personalInfo.state)
    data.append("city", personalInfo.city)
    data.append("best_days", getters.scheduledDaysJoined)
    data.append("best_hour", enumBestHour[getters.scheduledTimesJoined] || "q")
    data.append("card_number", user.cardNumber)
    data.append("card_owner", user.nam)
    data.append("product_name", "PAGBANK SAUDE - PLANO FAMILIAR")
    data.append("onix_code", rootGetters["account/onixCode"])
    data.append("email", user.email)
    data.append("telephone_1", user.phone)
    if (rootGetters["appointments/hasFile"]) {
      data.append("attachment[]", rootGetters["appointments/fileObject"])
    }
    // Esse campo é obrigatório. Deve haver um campo para selecionar o tipo de exame
    data.append("description", "Exame Normal")
    data.append("type", "CO")
    data.append("exam_group", "NI")

    return API.post(AppointmentsURLs.CREATE_SCHEDULE_EXAM, data, {
      headers: {
        "content-type": "multipart/form-data",
        "Transaction-Key": "dpnQ8FYWeqm8aVYbgkr7UVQdAuCLFU8RZAwMvseHxBfsDXqzaV",
      },
    })
  },

  createSchedule: ({ rootGetters, getters }, isVideo) => {
    const user = rootGetters["account/loggedUser"]
    const personalInfo = rootGetters["appointments/personalInfo"]
    const speciality = rootGetters["appointments/speciality"]

    const data = {
      patient_name: user.name,
      cpf: user.cpf,
      description: isVideo ? "TELEMEDICINA" : "",
      patient_birthdate: user.birthDate,
      specialty: speciality,
      uf: personalInfo.state,
      city: personalInfo.city,
      zone: personalInfo.district,
      best_days: getters.scheduledDaysJoined,
      best_hour: enumBestHour[getters.scheduledTimesJoined] || "q",
      card_number: user.cardNumber,
      card_owner: user.name,
      product_name: "PAGBANK SAUDE - PLANO FAMILIAR",
      onix_code: rootGetters["account/onixCode"],
      email: user.email,
      telephone_1: user.phone,
      type: "CO",
      attachment: null,
    }

    return API.post(AppointmentsURLs.CREATE_SCHEDULE, data, {
      headers: {
        "Transaction-Key": "dpnQ8FYWeqm8aVYbgkr7UVQdAuCLFU8RZAwMvseHxBfsDXqzaV",
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
