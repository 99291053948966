<template>
    <div class="rounded-lg shadow mb-10">
        <div class="p-4">
            <div class="flex justify-between text-sm font-bold text-gray-500">
                <div>CONSULTA</div>
                <div>DATA</div>
            </div>
            <div class="flex justify-between font-bold text-dark">
                <div>{{ appointment.speciality }}</div>
                <div>{{ appointment.date }}</div>
            </div>
        </div>
        <div class="relative px-4 flex flex-col font-bold text-left pt-32 pb-2">
            <div class="map-bg"></div>
            <div class="z-10">
                <div class="text-2xl text-black">{{ appointment.venue.name }}</div>
                <div>{{ appointment.venue.address }}</div>
                <div>{{ appointment.venue.city }} - {{ appointment.venue.state }}</div>
            </div>
        </div>
        <div class="border-t rounded-b-lg">
            <div class="flex justify-between">
                <a :href="helpURL" target="_blank" class="flex-grow p-4 font-bold text-blue">Ajuda</a>
                <div class="border-r"></div>
                <router-link :to="{ name: 'schedule.details', params: { appointment: appointment } }" class="flex-grow p-4 font-bold text-blue">Ver detalhes</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import whatsapp from '../../../utils/whatsapp'

export default {
    props: ['appointment'],

    computed: {
        
        helpURL() {

            return whatsapp.WHATSAPP_URL
        }
    }
}
</script>

<style lang="scss" scoped>
.map-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-image: url(../../../assets/maps-bg.jpg);
    opacity: 0.35;
}
</style>