<template>
    <Page back-to="appointment.inperson.schedule" title="SOLICITAÇÃO PARA AGENDAMENTO DE CONSULTAS" :steps="4" :current-step="4" :loading="loading">

        <h4 class="navigation-subtitle">Valide as informações</h4>

        <div class="mt-12 text-left font-medium">
            
            <h1 class="text-gray-500 mb-8">CONFIRME OS DADOS ABAIXO:</h1>

            <h1 class="text-gray-500">NOME DO SOLICITANTE:</h1>
            <p>{{ this.personalInfo.dependent }}</p>

            <h1 class="text-gray-500 mt-6">ENDEREÇO</h1>
            <p>{{ this.personalInfo.zipcode }}</p>
            <p>{{ this.addressText }}</p>

            <h1 class="text-gray-500 mt-6">ESPECIALIDADE:</h1>
            <p>{{ this.speciality }}</p>
            
            <h1 class="text-gray-500 mt-6">DIA E PERÍODO DE REFERÊNCIA:</h1>
            <p>{{ this.scheduledDays }} - {{ this.scheduledTimes }}</p>

            <button class="btn btn-blue btn-lg mb-12 mt-16" @click="create">SOLICITAR</button>
        </div>
    </Page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Page from '../../../components/app/Page'
import ClientErrorException from '../../../exceptions/ClientErrorException'

export default {
    components: { Page },

    data() {
        return {
            loading: false
        }
    },

    computed: {

        ...mapGetters('appointments', ['personalInfo', 'addressText', 'speciality', 'scheduledTimes', 'scheduledDays']),
    },

    methods: {

        ...mapActions('appointments', ['createSchedule']),

        create() {

            this.loading = true

            this.createSchedule()
            .then((response) => {

                const message = response.data.message
                this.$router.push({ name: 'appointment.inperson.success', params: { message } })
            })
            .catch(err => {
                switch (err.constructor) {
                    case ClientErrorException:
                        console.error(err)
                        break;
                    default:
                        console.error(err)
                        break;
                }
            })
            .finally(() => {
                this.loading = false
            })
        },
    }
}
</script>